
import { Agreement, Breadcrumb, Property } from "@/interfaces";

import Vue, { PropType } from "vue";

export default Vue.extend({
  props: {
    agreement: {
      type: Object as PropType<Agreement>,
      required: true
    }
  },
  computed: {
    breadcrumbs(): Breadcrumb[] {
      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/"
        },
        {
          text: "建物",
          disabled: false,
          to: "/buildings"
        },
        {
          text: `${this.agreement.building.name}`,
          disabled: false,
          to: `/building/${this.agreement.building.id}/property`
        },
        {
          text: "編集履歴",
          disabled: false
        }
      ];
    },
    properties(): Property[] | false {
      if (this.agreement.properties) {
        return this.agreement.properties;
      }

      return false;
    }
  }
});
